/** Classes for the displayed toast **/
.Toastify__toast {
    border-radius: 20px;
    font-size: 12px;
    font-family: 'D-DIN';
    margin: 10px 5px;
}

.Toastify__progress-bar {
    height: 2px;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    display: none;
}